import { MyBound } from "@/const";
import BrowserWsClient from "@/utils/net/BrowserWsClient";
import { client_proto, vipbetredate_proto } from "@/utils/net/INet";

export namespace MyBoundService {
  // 查询vipbet是否有奖励
  export const reqVipBetReadte = (uid: number) => {
    BrowserWsClient.instance.handleSendData({
      route: MyBound.Req.vipBetReadte,
      dsttype: client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_VIP_BET_REDATE,
      cmd: vipbetredate_proto.ROOMALLOC_CMD.ROOMALLOC_CMD_VIP_BET_REDATE_INFO_REQ,
      data: {
        uid,
      },
    });
  };

  export const reqVipBetReadteReward = (uid: number) => {
    BrowserWsClient.instance.handleSendData({
      route: MyBound.Req.vipBetReadteReward,
      dsttype: client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_VIP_BET_REDATE,
      cmd: vipbetredate_proto.ROOMALLOC_CMD.ROOMALLOC_CMD_VIP_BET_REDATE_REQ,
      data: {
        uid,
      },
    });
  };
}
