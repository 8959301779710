import "./index.css";
import "animate.css";
import { CSSProperties, useEffect, useMemo, useRef, useState } from "react";
import { useAppSelector } from "@/redux/store";
import { rankService, VipService, WalletService, SpinService, AgentService } from "@/service";
import { useMount, useUnmount, useInterval } from "react-use";
import Link from "next/link";
import numeral from "numeral";
import { MyBoundService } from "@/service/my-bouns";
import { getNowVipReward } from "./helper";
import { useDispatch } from "react-redux";
import { MyBonusActions } from "@/redux/features/my-bonus-slice";
import { StorageData } from "@/utils/net/storage/StorageData";
import { timeRemaining, TimeRemaining } from "@/utils/dayjs";

import Img from "@/components/Img";
import { EventGlobalManager } from "@/utils/events";
import { MyBound, StoreKey } from "@/const";
import { cdnPrefix, i18n } from "@/utils";
import { Loading, useLoadState } from "@/components/Loading";
import { Button } from "@/components/Button";
import { LazyImage } from "@/components/LazyloadImage";
import EndTime from "@/components/EndTime";
import { GiftPop } from "@/widget/GiftPop/index";
import { pngList } from "../png";
import { AgentRankService } from "@/service/agent-rank";
import { usePathname } from "next/navigation";
import { formatNumber } from "accounting";

type VipItem = {
  name?: string;
  vipLv?: number;
  reward_id: number;
  type: number;
  bonus: number;
  next_time?: number;
  rewardType: RewardType;
  reward_money?: number;
  rank?: number;
  state?: number;
  reward?: number;
};

export enum RewardType {
  upgrade = 1,
  week = 2,
  rank = 3,
}

export const VIP_REWARD_ID = {
  VIP_REWARD_NULL: 0,
  VIP_REWARD_UPGRADE: 1,
  VIP_REWARD_WEEKLY: 2,
};

let submitIsLock = false;
//大小冒泡排序
const bubbleSort = (compareList: VipItem[]) => {
  const len: number = compareList.length;
  for (let j: number = 0; j < len - 1; j++) {
    if (compareList[j].bonus > compareList[j + 1].bonus) {
      [compareList[j], compareList[j + 1]] = [compareList[j + 1], compareList[j]];
    }
  }
  return compareList;
};

const transMoney = (value: number) => {
  const integerPart = Math.floor(value / 1000);
  const pt = numeral(integerPart).format("0.00a", (value: number) => {
    return Math.trunc(value);
  });
  const regex = /^(\d+(\.\d+)?)([a-zA-Z])?$/;
  const match = pt.match(regex);
  if (match) {
    const numberPart = parseFloat(match[1]);
    const unit = match[3] || ""; // 单位可能为空
    const integerPart = Math.trunc(numberPart);
    return `${integerPart}${unit}`;
  }
};

const transRate = (numStr: string) => {
  const num = parseFloat(numStr);
  if (num % 1 === 0) {
    return num.toString();
  } else {
    const decimalPlaces = numStr.split(".")[1].replace(/0+$/, "").length; // 计算实际需要的小数位数
    return num.toFixed(decimalPlaces);
  }
};

const currencySymbol = i18n.common("CURRENCY_SYMBOL");
const Reward = (props: any) => {
  const visible = useAppSelector((state) => state.myBonusSlice.rewardListPanelVisible);

  const dispatch = useDispatch();

  const [wrapStyle, setWrapStyle] = useState<CSSProperties>({
    display: "none",
  });

  const [contentStyle, setContentStyle] = useState<CSSProperties>({
    top: "-100%",
  });

  const pathname = usePathname();
  //是否展示代理
  const [isAgent, setIsAgent] = useState(false);
  const [weekList, setWeekList] = useState<VipItem[] | null>(null);
  //升级奖励
  const [upgradeRewardList, setUpgradeRewardList] = useState<VipItem[]>([]);
  //个人排行榜数据
  const [rankRewardList, setRankRewardList] = useState<VipItem[] | null>(null);
  const [vipNextTime, setVipNextTime] = useState(0);

  const [isLoading, setIsLoading] = useLoadState(false);
  const [isRewardModalOpen, setIsRewardModalOpen] = useState(false);

  const usr_basic_info = useAppSelector((state) => state.authSlice?.basic_info);
  const rewardListResp = useAppSelector((state) => state.rankSlice?.rewardList);
  // const wheelInfo = useAppSelector((state) => state.wheelSlice);
  const curData = useAppSelector((state) => state.spinSlice.curData);
  const agentInfo = useAppSelector((state) => state.agentSlice.checkInviteStatus);
  const asset = useAppSelector((state) => state?.authSlice.asset);
  const vip_config_info = useAppSelector((state) => state.vipSlice.vipConfigInfo);
  const depositInfo = useAppSelector((state) => state.wallet.depositInfo);
  const vipBetInfo = useAppSelector((state) => state.myBonusSlice.vipBetReadte);
  const vipBetReadteReward = useAppSelector((state) => state.myBonusSlice.vipBetReadteReward);
  const agentRewardList = useAppSelector((state) => state.agentRankSlice.rewardListResp);

  useMount(() => {
    rankService.reqRewardList(usr_basic_info?.uid!);
    // WheelService.requestWheelInfo(usr_basic_info?.uid!);
    SpinService.reqCurData();
    VipService.getVipPageData(usr_basic_info?.uid!);
    // if (!vip_config_info?.vip_list) {
    // }
    MyBoundService.reqVipBetReadte(usr_basic_info?.uid!);

    AgentRankService.requestRankRewardList(usr_basic_info?.uid!);

    AgentService.requestInviteState(usr_basic_info?.uid!);
  });

  useEffect(() => {
    WalletService.reqDepositInfo();
  }, [pathname]);

  // console.log("agentRewardList", agentRewardList);
  const vipBetInfoReward = useMemo(() => {
    if (vipBetInfo.info) {
      try {
        return JSON.parse(vipBetInfo.info);
      } catch (ex) {
        return 0;
      }
    }
    return 0;
  }, [vipBetInfo]);

  useUnmount(() => {
    dispatch(MyBonusActions.setRewardListPanelVisible(false));
  });
  // console.log("vipBetInfoReward", vipBetInfoReward);
  // console.log("vipBetReadteReward", vipBetReadteReward);
  //判断是否展示首次充值奖励
  // console.log("usr_basic_info", usr_basic_info);

  //首次充值
  const isFirstRecharge = useMemo(() => {
    return asset && asset.rechargecount === 0;
  }, [asset]);

  // console.log("myBoundInfo", myBoundInfo);

  //每日奖励

  //判断是否展示代理
  useEffect(() => {
    if (agentInfo) {
      const { flow_limit = 0, total_charge = 0, charge_limit = 0, total_flow = 0, bonus } = agentInfo;
      let state = 0;
      let chargeGet = false;
      if (total_charge >= charge_limit) {
        chargeGet = true;
      } else {
        chargeGet = false;
      }
      let flowGet = false;
      if (total_flow >= flow_limit) {
        flowGet = true;
      } else {
        flowGet = false;
      }

      if (!chargeGet) {
        state = 0; //充值不达标
      } else {
        if (!flowGet) {
          state = 1; // 流水不达标
        } else {
          state = 2; // 都达标了
        }
      }

      if (state !== 2 && bonus !== 0) {
        setIsAgent(true);
      }
    }
  }, [agentInfo]);

  // 获取可领取奖励列表
  useEffect(() => {
    if (vip_config_info?.vip_list && vip_config_info.vip_list.length > 0) {
      // console.log("vip_config_info", vip_config_info.vip_list);
      const { weekList, upgradeList } = getNowVipReward(vip_config_info);
      setUpgradeRewardList(bubbleSort(upgradeList));
      setWeekList(weekList);
      const nextTime = weekList?.[weekList?.length - 1]?.next_time;
      setVipNextTime(nextTime!);
      console.log(weekList);
    }
  }, [vip_config_info?.vip_list]);

  useInterval(() => {
    if (vipNextTime > 0) {
      setVipNextTime(vipNextTime - 1);
    }
  }, 1000);

  const Countdown = useMemo(() => {
    const data = timeRemaining(vipNextTime * 1000) as TimeRemaining;
    const hours = Number(data?.hours) > 0 ? Number(data?.hours) : "00";
    const minutes = Number(data?.minutes) > 0 ? data?.minutes : "00";
    const seconds = Number(data?.seconds) > 0 ? data?.seconds : "00";
    return `${hours}:${minutes}:${seconds}`;
  }, [vipNextTime]);

  //个人排行榜
  useEffect(() => {
    const rankRewardList = rewardListResp.reward_list;
    if (rankRewardList) {
      setRankRewardList(
        rankRewardList.map(
          (item: any) =>
            ({
              rewardType: RewardType.rank,
              reward_id: item.reward_id,
              type: item.rank_list_type,
              name: item.name,
              reward_money: item.reward_money,
              rank: item.rank,
              state: item.state,
              reward: item.reward,
            }) as VipItem,
        ),
      );
    }
  }, [rewardListResp]);

  useEffect(() => {
    if (visible) {
      setWrapStyle({ display: "block" });
      setTimeout(() => {
        setContentStyle({
          top: "1rem",
        });
      }, 16);
    } else {
      setContentStyle({ top: "-100%" });
      setTimeout(() => {
        setWrapStyle({ display: "none" });
      }, 300);
    }
  }, [visible]);

  const [reward, setReward] = useState(0);
  //获取下注奖励
  const onGetBetReward = () => {
    if (submitIsLock) {
      return;
    }
    setIsLoading(true);
    submitIsLock = true;
    MyBoundService.reqVipBetReadteReward(usr_basic_info?.uid!);
    EventGlobalManager.on(MyBound.Resp.vipBetReadteReward, (data: any) => {
      const {
        data: { reward, sign },
      } = data;
      // 领取成功后展示礼品弹窗
      if (sign === 1) {
        setReward(reward);
        setIsRewardModalOpen(true);
      }
      MyBoundService.reqVipBetReadte(usr_basic_info?.uid!);
      setIsLoading(false);
      submitIsLock = false;
    });
  };
  const { amount, target } = curData;

  const inputRef = useRef<any>();

  const handleDocumentClick = (e: Event) => {
    if (!inputRef.current) {
      return;
    }
    if (
      !inputRef?.current?.contains(e?.target) &&
      !props?.bodyDom?.current?.contains(e?.target) &&
      inputRef?.current !== e?.target
    ) {
      dispatch(MyBonusActions.setRewardListPanelVisible(false));
    }
  };

  useEffect(() => {
    if (visible) {
      document.addEventListener("click", handleDocumentClick);
    } else {
      document.removeEventListener("click", handleDocumentClick);
    }
  }, [visible]);

  const agentRankList = useMemo(() => {
    if (agentRewardList && agentRewardList.reward_list) {
      return agentRewardList.reward_list;
    }
    return [];
  }, [agentRewardList]);

  const invitionRewrads = useMemo(() => {
    if (agentInfo) {
      const { flow_limit = 0, total_charge = 0, charge_limit = 0, total_flow = 0, bonus } = agentInfo;
      let state = 0;
      let chargeGet = false;
      if (total_charge >= charge_limit) {
        chargeGet = true;
      } else {
        chargeGet = false;
      }
      let flowGet = false;
      if (total_flow >= flow_limit) {
        flowGet = true;
      } else {
        flowGet = false;
      }

      if (!chargeGet) {
        state = 0; //充值不达标
      } else {
        if (!flowGet) {
          state = 1; // 流水不达标
        } else {
          state = 2; // 都达标了
        }
      }

      if (state !== 2 && bonus !== 0) {
        return {
          title: i18n.earn("AGENT_INVITATION_REWARDS"),
          decription:
            state === 0
              ? i18n.home(
                  "MYBONUS_DES_INVITED_CHARGE",
                  currencySymbol + formatNumber(charge_limit / 1000),
                  currencySymbol + formatNumber(bonus! / 1000),
                )
              : state === 1
                ? i18n.home(
                    "MYBONUS_DES_INVITED_FLOW",
                    currencySymbol + formatNumber(flow_limit / 1000),
                    currencySymbol + formatNumber(bonus! / 1000),
                  )
                : "",
        };
      }
    }
    return null;
  }, [agentInfo]);

  return (
    <Loading
      loading={isLoading}
      zIndex={102}
      onClose={() => {
        setIsLoading(false);
      }}
    >
      <div className="reward-wrap" style={wrapStyle}>
        <div className="reward-content" style={contentStyle} ref={inputRef}>
          <div className="reward-content-in">
            <div className="list">
              {upgradeRewardList && upgradeRewardList?.length > 0 && (
                <div className="item">
                  <div className="left">
                    <div className="icon">
                      <Img src={pngList.icon_vip_sj} alt="" height={"0.5rem"} />
                    </div>
                    <div className="info">
                      <div className="title">
                        {i18n.home("MYBONUS_TITLE_VIPUPGRADE", upgradeRewardList[upgradeRewardList?.length - 1]?.vipLv)}
                      </div>
                      <div className="vs-status item-bottom">
                        <span className="vs-txt">
                          {currencySymbol}
                          {transMoney(upgradeRewardList[upgradeRewardList.length - 1].bonus)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="right">
                    <Button className="!min-w-[1.85rem] !text-[0.26rem]">
                      <Link
                        prefetch={true}
                        href={`/vip?gt_params=${upgradeRewardList[upgradeRewardList?.length - 1]?.vipLv}`}
                      >
                        {i18n.home("MYBONUS_BTNLAB_JUMP")}
                      </Link>
                    </Button>
                  </div>
                </div>
              )}
              {weekList && weekList.length > 0 && (
                <div className="item">
                  <div className="left">
                    <div className="icon">
                      <Img src={pngList.icon_vip} alt="" height={"0.5rem"} />
                    </div>
                    <div className="info">
                      <div className="title">
                        {i18n.home("MYBONUS_TITLE_VIPWEEK", weekList[weekList?.length - 1]?.vipLv)}
                      </div>
                      <div className="vs-status item-bottom">
                        <span className="vs-txt">
                          {currencySymbol}
                          {transMoney(weekList[weekList?.length - 1]?.bonus)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="right">
                    {vipNextTime > 0 ? (
                      <p className="next-time !min-w-[1.85rem]">
                        {/* {Countdown} */}
                        <EndTime time={vipNextTime} />
                      </p>
                    ) : (
                      <Button className="!min-w-[1.85rem] !text-[0.26rem]">
                        <Link prefetch={true} href={`/vip?gt_params=${weekList[weekList?.length - 1]?.vipLv}`}>
                          {i18n.home("MYBONUS_BTNLAB_JUMP")}
                        </Link>
                      </Button>
                    )}
                  </div>
                </div>
              )}
              {rankRewardList &&
                rankRewardList.length > 0 &&
                rankRewardList.map((rankReward: VipItem, index: number) => {
                  return (
                    rankReward.state === 1 && (
                      <div className="item" key={index}>
                        <div className="left">
                          <div className="icon">
                            <Img
                              src={pngList.icon_phb}
                              alt=""
                              height={"0.5rem"}
                              isTO={true}
                              toUrl={pngList.topone_icon_phb}
                            />
                          </div>
                          <div className="info">
                            <div className="title">
                              {rankReward.name}(NO.{rankReward.rank})
                            </div>
                            <div className="vs-status item-bottom">
                              <span className="vs-txt">
                                {currencySymbol}
                                {transMoney(rankReward.reward_money!)}
                                <span className="vtt-txt">
                                  ({i18n.home("AGENT_REWARDS")}:
                                  <span>{transRate((rankReward.reward! / 1000).toFixed(3))}</span>%)
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="right">
                          <Button className="!min-w-[1.85rem] !text-[0.26rem]">
                            <Link prefetch={true} href="/ranking/myReward">
                              {i18n.home("MYBONUS_BTNLAB_JUMP")}
                            </Link>
                          </Button>
                        </div>
                      </div>
                    )
                  );
                })}
              {isFirstRecharge ? (
                <div className="item">
                  <div className="left">
                    <div className="icon">
                      <Img src={pngList.icon_sc} alt="" height={"0.5rem"} />
                    </div>
                    <div className="info">
                      <div className="title">{i18n.home("MYBONUS_TITLE_FIRSTDEPOSIT")}</div>
                      <div className="prim-status">
                        <span className="str-txt">
                          {i18n.home("MYBONUS_DES_FIRSTDEPOSIT")} {currencySymbol}
                          {depositInfo?.items && depositInfo?.items?.length > 0
                            ? depositInfo?.items[depositInfo?.items.length - 1].bonus / 1000
                            : ""}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="right">
                    <Button className="!min-w-[1.85rem] !text-[0.26rem]">
                      <Link
                        prefetch={true}
                        href="/shopping"
                        onClick={() => StorageData.local.set("firstCharge", false)}
                      >
                        {i18n.home("MYBONUS_BTNLAB_CLAIM")}
                      </Link>
                    </Button>
                  </div>
                </div>
              ) : null}
              {curData?.target! > 0 && (
                <div className="item">
                  <div className="left">
                    <div className="icon">
                      <Img src={pngList.icon_zp} alt="" height={"0.5rem"} />
                    </div>
                    <div className="info">
                      <div className="title">{i18n.home("MYBONUS_TITLE_WHEEL")}</div>
                      <div className="reward-progress item-bottom" style={{ backgroundImage: `` }}>
                        <span className="text">
                          ({currencySymbol}
                          {amount / 1000}/{currencySymbol}
                          {target / 1000})
                        </span>
                        <span className="cur-state" style={{ width: (amount / target) * 100 + "%" }}></span>
                      </div>
                    </div>
                  </div>
                  <div className="right">
                    <Button className="!min-w-[1.85rem] !text-[0.26rem]">
                      <Link prefetch={true} href="/turntable">
                        {i18n.home("MYBONUS_BTNLAB_JUMP")}
                      </Link>
                    </Button>
                  </div>
                </div>
              )}
              {/* 邀请奖励 */}
              {invitionRewrads && (
                <>
                  <div className="item">
                    <div className="left">
                      <div className="icon">
                        <Img src={pngList.icon_ir} alt="" className="w-[0.8rem]" height={"0.5rem"} />
                      </div>
                      <div className="info">
                        <div className="title">{invitionRewrads.title}</div>
                        <div className="prim-status item-bottom !mt-0 !leading-[1]">
                          <span className="str-txt !text-[#e6e6e6]">{invitionRewrads.decription}</span>
                        </div>
                      </div>
                    </div>
                    <div className="right">
                      <Button className="!min-w-[1.85rem] !text-[0.26rem]">
                        <Link href="/shopping">{i18n.home("MYBONUS_BTNLAB_JUMP")}</Link>
                      </Button>
                    </div>
                  </div>
                </>
              )}
              {/* 下注 */}
              {vipBetInfo.sign === 1 && (
                <>
                  <div className="item">
                    <div className="left">
                      <div className="icon">
                        <Img src={pngList.icon_fz} alt="" className="w-[0.8rem]" height={"0.5rem"} />
                      </div>
                      <div className="info">
                        <div className="title">{i18n.home("VIP_BET_REBATE")}</div>
                        <div className="prim-status item-bottom">
                          <span className="str-txt">
                            {i18n.home("MYBONUS_DES_VIP_BET_REBATE")} {(vipBetInfoReward.reward / 1000).toFixed(2)}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="right">
                      <Button className="!min-w-[1.85rem] !text-[0.26rem]" onClick={onGetBetReward}>
                        {i18n.home("MYBONUS_BTNLAB_CLAIM")}
                      </Button>
                    </div>
                  </div>
                </>
              )}

              {/* 代理排行榜 */}
              {agentRankList.length > 0 && (
                <>
                  {agentRankList.map((agentRank, index: number) => {
                    return (
                      <>
                        <div className="item" key={index}>
                          <div className="left">
                            <div className="icon">
                              <Img
                                src={pngList.icon_phb}
                                alt=""
                                height={"0.5rem"}
                                isTO={true}
                                toUrl={pngList.topone_icon_phb}
                              />
                            </div>
                            <div className="info">
                              <div className="title">
                                {i18n.wallet("WALLET_DETAIL_STATUS_27")}(NO.{agentRank.rank})
                              </div>
                              <div className="prim-status">
                                <span className="vs-txt">
                                  {currencySymbol}
                                  {agentRank.reward_money / 1000}
                                </span>
                                <span className="text-[#e6e6e6] ml-[.05rem]">({i18n.rank("RANK_REWARD_V")}</span>
                                <span className="str-txt">{agentRank.reward! / 10000}</span>
                                <span className="text-[#e6e6e6]">%)</span>
                              </div>
                            </div>
                          </div>
                          <div className="right">
                            <Button className="!min-w-[1.85rem] !text-[0.26rem]">
                              <Link
                                prefetch={true}
                                href="/ranking/myReward?type=2"
                                onClick={() => StorageData.local.set("firstCharge", false)}
                              >
                                {i18n.home("MYBONUS_BTNLAB_CLAIM")}
                              </Link>
                            </Button>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </>
              )}
            </div>
          </div>
          <div
            className="reward-dailog-close"
            onClick={() => dispatch(MyBonusActions.setRewardListPanelVisible(false))}
          >
            <Img src={pngList.jgan} alt="" />
          </div>
        </div>

        <div className="mask"></div>
      </div>
      <GiftPop isOpen={isRewardModalOpen} onClose={() => setIsRewardModalOpen(false)} rewardList={[{ num: reward }]} />
    </Loading>
  );
};

export default Reward;
